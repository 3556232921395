

























































import {Vue, Component, Prop, Watch} from 'vue-property-decorator';
import {
	CreateTimeSheetRecordPayload,
	TimeSheetPayDay,
	TimeSheetRecord, UpdateTimeSheetRecordPayload
} from "@/dto/payroll/timesheet/TimesheetPayloads";
import moment, {Moment} from "moment-timezone";
import TimesheetDetailsPeriodItem from "@/components/payroll/timesheet/TimesheetDetailsPeriodItem.vue";
import Application from "@/state/Application";
import TimesheetService from "@/services/payroll/TimesheetService";
import {ifValid, processError} from "@/utils/ComponentUtils";
import {IEmployment} from "@/dto/payroll/IEmployment";
import PortalDate from "@/components/common/PortalDate.vue";
import {Md5} from "ts-md5";

@Component(
	{
		components: {PortalDate, TimesheetDetailsPeriodItem},
		methods: {
			hashCode(obj: any): string {
				return Md5.hashStr(JSON.stringify(obj))
			},
			moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): Moment {
				return moment(inp, format, language, strict)
			}
		}
	}
)
export default class TimeSheetDetails extends Vue {

	@Prop()
	private timeFormat!: string

	@Prop()
	private employment!: IEmployment

	@Prop()
	private day!: TimeSheetPayDay

	@Prop()
	private totalForWeek!: string

	private manualStart = moment().format("HH:mm")
	private manualEnd: string | null = null

	private manualCreate = false

	get isClosed(): boolean {
		return !this.day.records.find(it => it.workPeriodEnd == null)
	}
	get isVacation(): boolean { return !!this.day.records.find(it => it.type == "VACATION")}
	get isSickLeave(): boolean { return !!this.day.records.find(it => it.type == "SICK_LEAVE") }

	private timerTrigger = 0;

	public mounted(): void {
		setInterval(
			() => {
				if (!this.isClosed) {
					this.timerTrigger += 1
				}
			},
			1000)
	}

	get title(): string {
		const month = moment(this.day.date).localeData().months()[moment(this.day.date).month()]
		const weekDay = moment(this.day.date).localeData().weekdaysShort()[moment(this.day.date).weekday()]
		return `${month} ${weekDay} ${moment(this.day.date).date()}`
	}

	private timer(): string {
		if (!this.isClosed && moment(this.day.date).dayOfYear() == moment().dayOfYear()) {
			let currentWorkDuration = this.day.durationInSeconds + 1;
			currentWorkDuration += Math.floor((new Date().getTime() - moment(this.day.records[this.day.records.length - 1].workPeriodStart).toDate().getTime()) / 1000)
			return `${Math.floor(currentWorkDuration / 3600)}h ${Math.floor(currentWorkDuration / 60 % 60)}m ${currentWorkDuration % 60}s`
		} else {
			return this.day.duration
		}
	}


	private autoCreateRecord(): void {
		Application.startLoading()
		TimesheetService.createRecord(
			CreateTimeSheetRecordPayload.builder()
				.workPeriodStart(new Date())
				.date(this.day.date)
				.employmentId(this.employment.id)
				.mode("AUTO")
				.build()
		).then(
			() => {
				Application.stopLoading()
				this.$emit("onCreateRecord")
			},
			err => processError(err, this)
		)
	}

	private manualCreateRecord(): void {
		ifValid(this, () => {
			Application.startLoading()
			TimesheetService.createRecord(
				CreateTimeSheetRecordPayload.builder()
					.date(this.day.date)
					.mode("MANUAL")
					.employmentId(this.employment.id)
					.workPeriodStart(new Date(`${this.day.date} ${this.manualStart}`))
					.workPeriodEnd(this.manualEnd ? new Date(`${this.day.date} ${this.manualEnd}`) : null)
					.build()
			).then(
				() => {
					Application.stopLoading()
					this.$emit("onCreateRecord")
				},
				err => processError(err, this)
			)
		})
	}

	private stopRecord(): void {
		Application.startLoading()
		const recordForClose = this.day.records.find(it => it.workPeriodEnd == null)
		TimesheetService.updateRecord(
			UpdateTimeSheetRecordPayload.builder()
				.id(recordForClose!.id)
				.employmentId(this.employment.id)
				.workPeriodEnd(new Date())
				.workPeriodStart(recordForClose!.workPeriodStart)
				.build()
		).then(
			() => {
				Application.stopLoading()
				this.$emit("onUpdateRecord")
			},
			err => processError(err, this)
		)
	}
}
