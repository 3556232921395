









































































import {Vue, Component, Prop} from 'vue-property-decorator';
import {
	CreateTimeSheetRecordPayload,
	TimeSheetPayDay, TimeSheetRecord,
	UpdateTimeSheetRecordPayload
} from "@/dto/payroll/timesheet/TimesheetPayloads";
import moment, {Moment} from "moment-timezone";
import Application from "@/state/Application";
import TimesheetService from "@/services/payroll/TimesheetService";
import {processError} from "@/utils/ComponentUtils";
import {IEmployment} from "@/dto/payroll/IEmployment";

@Component(
	{
		methods: {
			moment(inp?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): Moment {
				return moment(inp, format, language, strict)
			},
		}
	}
)
export default class TimeSheetDay extends Vue {

	private timerTrigger = 0

	@Prop()
	private day!: TimeSheetPayDay

	@Prop()
	private isSelected!: boolean

	@Prop()
	private employment!: IEmployment

	get isVacation(): boolean { return !!this.day.records.find(it => it.type == "VACATION") }
	get isSickLeave(): boolean { return !!this.day.records.find(it => it.type == "SICK_LEAVE") }
	get isCurrentDay(): boolean { return moment(this.day.date).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD") }

	get isClosed(): boolean {
		return !this.day.records.find(it => it.workPeriodEnd == null)
	}

	private autoCreateRecord(): void {
		Application.startLoading()
		TimesheetService.createRecord(
			CreateTimeSheetRecordPayload.builder()
				.workPeriodStart(new Date())
				.date(this.day.date)
				.employmentId(this.employment.id)
				.mode("AUTO")
				.build()
		).then(
			() => {
				Application.stopLoading()
				this.$emit("onCreateRecord")
			},
			err => processError(err, this)
		)
	}

	public mounted(): void {
		setInterval(
			() => {
				if (!this.isClosed) {
					this.timerTrigger += 1
				}
			},
			1000)
	}

	private processClick(): void {
		if (this.isSickLeave || this.isVacation) return;
		if (this.isClosed) {
			if (this.isCurrentDay) {
				this.autoCreateRecord()
			}
		} else {
			this.stopRecord()
		}
	}

	private stopRecord(): void {
		Application.startLoading()
		const recordForClose = this.day.records.find(it => it.workPeriodEnd == null)
		TimesheetService.updateRecord(
			UpdateTimeSheetRecordPayload.builder()
				.id(recordForClose!.id)
				.employmentId(this.employment.id)
				.workPeriodEnd(new Date())
				.workPeriodStart(recordForClose!.workPeriodStart)
				.build()
		).then(
			() => {
				Application.stopLoading()
				this.$emit("onUpdateRecord")
			},
			err => processError(err, this)
		)
	}

	private timer(): string {
		if (!this.isClosed && moment(this.day.date).dayOfYear() == moment().dayOfYear()) {
			let currentWorkDuration = this.day.durationInSeconds + 1;
			currentWorkDuration += Math.floor((new Date().getTime() - moment(this.day.records[this.day.records.length - 1].workPeriodStart).toDate().getTime()) / 1000)
			return `${Math.floor(currentWorkDuration / 3600)}h ${Math.floor(currentWorkDuration / 60 % 60)}m ${currentWorkDuration % 60}s`
		} else {
			return this.day.duration
		}
	}
}

